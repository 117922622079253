<template>
    <div>
        <v-snackbar top v-model="notification.show" :color="notification.color">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>
        <v-dialog content-class="successdialog" v-model="show" max-width="400px" height="300px" style="overflow-y: hidden !important">
            <v-card class="rounded_card sky" v-if="!loading" id="successdialog">
                <div class="exhaust-flame"></div>
                <ul class="exhaust-fumes">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
                <v-card-title>
                    <v-btn color="primary" class="ml-auto" @click="show = false" icon><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <div class="text-center content-container">
                    <span class="success-title">Payment completed! </span>
                    <p class="subheading">
                        Welcome to Quaro
                        <!-- <span class="booked-package">{{ booked_package }}</span> -->
                    </p>
                </div>
                <v-card-text>
                    <div class="text-center img-conainer">
                        <!-- Img rocket -->
                        <img class="rocket" width="180px" :src="rocket03" />
                    </div>

                    <ul class="star">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </v-card-text>
                <!-- <v-card-actions>
          <v-btn block @click="show=false" color="primary" class="mx-auto">Let´s go!</v-btn>
        </v-card-actions> -->
            </v-card>
            <v-card v-else>
                <v-row>
                    <v-progress-circular color="primary" class="mx-auto ma-10" indeterminate></v-progress-circular>
                </v-row>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "CheckoutSuccess",
        props: {},
        data: () => ({
            notification: { show: false },
            loading: true,
            payment_status: null,
            booked_package: "Pro",
            rocket01: require("../../assets/icons/__rocket01.svg"),
            rocket02: require("../../assets/icons/__rocket02.svg"),
            rocket03: require("../../assets/icons/__rocket03.svg"),
            show: true,
            auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH)
        }),
        methods: {
            // setTitle(price_id) {
            // if (
            //     price_id == process.env.VUE_APP_SINGLE_PRICE_ID_MONTHLY ||
            //     price_id == process.env.VUE_APP_SINGLE_PRICE_ID_YEARLY ||
            //     price_id == process.env.VUE_APP_SINGLE_PRICE_ID_MONTHLY_EARLY_BIRD ||
            //     price_id == process.env.VUE_APP_SINGLE_PRICE_ID_YEARLY_EARLY_BIRD
            // ) {
            //     this.booked_package = "Single"
            // } else if (price_id == process.env.VUE_APP_ADVANCED_PRICE_ID_MONTHLY || price_id == process.env.VUE_APP_ADVANCED_PRICE_ID_YEARLY) {
            //     this.booked_package = "Advanced"
            // } else if (price_id == process.env.VUE_APP_PRO_PRICE_ID_MONTHLY || price_id == process.env.VUE_APP_PRO_PRICE_ID_YEARLY) {
            //     this.booked_package = "Pro"
            // }
            // },
            checkPaymentStatus() {
                this.loading = true
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/checkout/validate", { company_id: localStorage.company_id, user: localStorage.user }, { headers: { Authorization: this.auth } })
                    .then((response) => {
                        console.log(response.body)
                        // this.setTitle(response.body.price_id)
                        if (response.body.upgraded) {
                            this.loading = false
                            //redo startup
                            let user = this.CryptoJS.AES.decrypt(localStorage.user, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
                            this.$store.dispatch("FETCH_STARTUP_DATA", user)
                        } else {
                            this.show = false
                            console.log(response.body)
                            this.loading = false
                            this.notification = this.$helpers.createNotification(
                                "An error acurred! Please contact support via chat or via email! contact@quaro.io",
                                "mdi-alert-circle-outline",
                                "#FCBA03"
                            )
                        }
                    })
                    .catch((error) => {
                        this.loading = false
                        this.show = false
                        console.log(error)
                        this.notification = this.$helpers.createNotification("An error acurred! Please contact support via chat or via email! contact@quaro.io", "mdi-alert-circle-outline", "#FCBA03")
                    })
            }
        },
        created() {
            this.checkPaymentStatus()
        }
    }
</script>
<style lang="scss" scoped>
    .successdialog {
        overflow-y: hidden !important;
    }
    #successdialog {
        overflow-y: hidden !important;
    }
    .v-dialog .v-dialog--active {
        overflow-y: hidden !important;
    }
    .sky {
        background: linear-gradient(to bottom, #999494 10%, #ffffff 60%);
    }
    .success-title {
        color: black;
        font-size: 2em;
        font-weight: 500;
        z-index: 2;
    }
    .subheading {
        color: rgb(129, 129, 129);
        font-weight: bold;
    }
    .booked-package {
        color: black;
        font-weight: bold;
    }
    .rocket {
        animation: bounce 0.5s infinite;
    }

    .img-conainer {
        /* background-color: beige; */
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 55px;
        padding-bottom: 55px;
        border-radius: 50%;
    }
    .star li {
        list-style: none;
        position: absolute;
        &:before,
        &:after {
            content: "";
            position: absolute;
            background-color: white;
        }
        &:before {
            width: 10px;
            height: 2px;
            border-radius: 50%;
        }
        &:after {
            height: 8px;
            width: 2px;
            left: 4px;
            top: -3px;
        }
        &:first-child {
            top: 17px;
            left: 143px; //check
            animation: twinkle 0.4s infinite;
        }
        &:nth-child(2) {
            top: 38px;
            left: 60px; //check
            animation: twinkle 0.5s infinite;
            &:before {
                height: 1px;
                width: 5px;
            }
            &:after {
                width: 1px;
                height: 5px;
                top: -2px;
                left: 2px;
            }
        }
        &:nth-child(3) {
            left: 280px;
            top: 160px; //Check
            animation: twinkle 1s infinite;
        }
        &:nth-child(4) {
            left: 100px;
            top: 200px; //check
            animation: twinkle 0.5s ease infinite;
        }
        &:nth-child(5) {
            left: 225px;
            top: 55px; //Check
            animation: twinkle 0.4s ease infinite;
        }
        &:nth-child(6) {
            top: 112px;
            left: 72px;
            animation: twinkle 0.2s infinite;
            &:before {
                height: 1px;
                width: 5px;
            }
            &:after {
                width: 1px;
                height: 5px;
                top: -2px;
                left: 2px;
            }
        }
    }
    .exhaust-flame {
        position: absolute;
        bottom: 25px;
        width: 50px;
        background: linear-gradient(to top, transparent 10%, var(--v-primary-base) 100%);
        height: 75px;
        left: calc(50% - 25px);
        animation: exhaust 0.2s infinite;
    }
    .exhaust-fumes li {
        width: 60px;
        height: 60px;
        background-color: var(--v-primary-base);
        list-style: none;
        position: absolute;
        border-radius: 100%;
        &:first-child {
            width: 200px;
            height: 200px;
            bottom: -150px;
            right: 25px;
            animation: fumes 5s infinite;
        }
        &:nth-child(2) {
            width: 150px;
            height: 150px;
            left: 20px;
            bottom: -75px;
            animation: fumes 3.2s infinite;
        }
        &:nth-child(3) {
            width: 120px;
            height: 120px;
            left: -40px;
            display: none;
            top: 200px;
            animation: fumes 3s 1s infinite;
        }
        &:nth-child(4) {
            width: 100px;
            height: 100px;
            left: -170px;
            display: none;
            animation: fumes 4s 2s infinite;
            top: 380px;
        }
        &:nth-child(5) {
            width: 130px;
            height: 130px;
            left: -120px;
            display: none;
            top: 350px;
            animation: fumes 5s infinite;
        }
        &:nth-child(6) {
            width: 200px;
            height: 200px;
            left: calc(50% - 100px);
            bottom: -100px;
            animation: fumes2 10s infinite;
        }
        &:nth-child(7) {
            width: 100px;
            height: 100px;
            right: 60px;
            bottom: -75px;
        }
        &:nth-child(8) {
            width: 110px;
            height: 110px;
            left: 65px;
            bottom: -70px;
        }
        &:nth-child(9) {
            width: 90px;
            height: 90px;
            left: 200px;
            display: none;
            bottom: 380px;
            animation: fumes 20s infinite;
        }
    }
    @keyframes fumes {
        50% {
            transform: scale(1.5);
            background-color: transparent;
        }
        51% {
            transform: scale(0.8);
        }
        100% {
            background-color: var(--v-primary-base);
            transform: scale(1);
        }
    }
    @keyframes exhaust {
        0% {
            background: linear-gradient(to bottom, transparent 10%, var(--v-primary-base) 100%);
        }
        50% {
            background: linear-gradient(to bottom, transparent 8%, var(--v-primary-base) 100%);
        }
        75% {
            background: linear-gradient(to bottom, transparent 12%, var(--v-primary-base) 100%);
        }
    }

    @keyframes fumes2 {
        50% {
            transform: scale(1.1);
        }
    }
    @keyframes bounce {
        0% {
            transform: translate3d(0px, 0px, 0);
        }
        50% {
            transform: translate3d(0px, -4px, 0);
        }
        100% {
            transform: translate3d(0px, 0px, 0);
        }
    }
    @keyframes twinkle {
        80% {
            transform: scale(1.1);
            opacity: 0.7;
        }
    }
</style>
